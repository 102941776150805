import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import auth from "lib/authentication";
import getEnvironment from "lib/env";

const usersApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: getEnvironment().baseUrl,
		prepareHeaders: auth.getHeaders,
	}),
	providesTags: ["user"],
	reducerPath: "users",
	endpoints: (build) => ({
		getUser: build.query({
			providesTags: "user",
			query: () => ({
				url: "users/me",
			}),
		}),
	}),
});

export const { useGetUserQuery } = usersApi;
export default usersApi;
