import { Form, Field } from "react-final-form";
import {
  CTable,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import Input from "./input";
import dayjs from "dayjs";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const EditTranslationsRow = (props) => {
  const validateInput = (value) => {
    if (value == "" || value == null) {
      return "Field is empty";
    }
    if (value.length < 1) {
      return "Too short searchterm";
    }
    return undefined;
  };

  const { translation } = props;

  return (
    <Form
      onSubmit={(value) => props.onSubmit(value)}
      initialValues={translation}
      render={({ handleSubmit, valid }) => (
        <form onSubmit={handleSubmit}>
          <CTable>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>LCID</CTableHeaderCell>
                <CTableHeaderCell>Text</CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableRow>
              <CTableDataCell>
                <div>{translation.lcid}</div>
              </CTableDataCell>
              <CTableDataCell>
                <Field
                  name="text"
                  component={Input}
                  style={{ backgroundColor: "white" }}
                  validate={validateInput}
                />
              </CTableDataCell>
            </CTableRow>
          </CTable>
        </form>
      )}
    />
  );
};

export default EditTranslationsRow;
