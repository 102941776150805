import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setVisible } from "features/sidebar/slice";
import { useGetUserQuery } from "features/users/api";

import {
	CContainer,
	CHeader,
	CHeaderBrand,
	CHeaderNav,
	CHeaderToggler,
	CNavLink,
	CNavItem,
	CDropdown,
	CDropdownItem,
	CDropdownToggle,
	CDropdownMenu,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import HaldorSymbol from "assets/haldor_symbol.svg";

const AppHeader = () => {
	const dispatch = useDispatch();
	const sidebarVisible = useSelector((state) => state.sidebar.visible);
	const { data: user } = useGetUserQuery();

	return (
		<CHeader position="sticky" className="mb-4">
			<CContainer fluid>
				<CHeaderToggler
					className="ps-1"
					onClick={() => dispatch(setVisible(!sidebarVisible))}
				>
					<CIcon icon={cilMenu} size="lg" />
				</CHeaderToggler>

				<CHeaderBrand className="mx-auto d-block d-md-none" to="/">
					<img src={HaldorSymbol} height={35} alt="Haldor logo" />
				</CHeaderBrand>

				<CHeaderNav className="d-none d-md-flex me-auto">
					<CNavItem>
						<CNavLink to="/" component={NavLink}>
							Hem
						</CNavLink>
					</CNavItem>

					<CNavItem>
						<CNavLink to="/add" component={NavLink}>
							Add Translation
						</CNavLink>
					</CNavItem>
				</CHeaderNav>

				<CHeaderNav className="ms-3">
					<CDropdown variant="nav-item">
						<CDropdownToggle
							placement="bottom-end"
							className="py-0"
							caret={false}
						>
							{user != null ? user.displayName : 'Account'}
						</CDropdownToggle>

						<CDropdownMenu className="pt-0" placement="bottom-end">
							<CDropdownItem href="/logout">Logout</CDropdownItem>
						</CDropdownMenu>
					</CDropdown>
				</CHeaderNav>
			</CContainer>
		</CHeader>
	);
};

export default AppHeader;
