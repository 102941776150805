import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	visible: true,
	unfoldable: false,
};

export const sidebarSlice = createSlice({
	name: 'sidebar',
	initialState,
	reducers: {
		setVisible: (state, action) => {
			state.visible = action.payload;
		},
		setUnfoldable: (state, action) => {
			state.unfoldable = action.payload;
		},
	},
});

export const { setVisible, setUnfoldable } = sidebarSlice.actions;
export default sidebarSlice.reducer;