import React from "react";
import Translations from "containers/Forms/translations";
import { useAddTranslationMutation } from "features/translations/api";

const Add = (props) => {
  const [addTranslation] = useAddTranslationMutation();
  const onSubmit = (value) => {
    addTranslation(value);
  };
  return (
    <div>
      <h1>Add translation</h1>
      <Translations onSubmit={onSubmit}></Translations>
    </div>
  );
};

export default Add;
