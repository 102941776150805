import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	service: null,
	servicePart: null,
};

export const selectedServiceSlice = createSlice({
	name: 'selectedService',
	initialState,
	reducers: {
		setService: (state, action) => {
			state.service = action.payload;
		},
		setServicePart: (state, action) => {
			state.servicePart = action.payload;
		},
	},
});

export const { setService, setServicePart } = selectedServiceSlice.actions;
export default selectedServiceSlice.reducer;