import { Form, Field } from "react-final-form";
import { CTable, CTableDataCell, CTableRow, CButton } from "@coreui/react";
import Input from "./input";

const SearchForm = (props) => {
  const validateInput = (value) => {
    if (value == "" || value == null) {
      return "Field is empty";
    }
    if (value.length < 2) {
      return "Too short searchterm";
    }
    return undefined;
  };
  return (
    <Form
      onSubmit={(value) => props.onSubmit(value)}
      render={({ handleSubmit, valid }) => (
        <form onSubmit={handleSubmit}>
          <CTable>
            <CTableRow>
              <CTableDataCell>
                <Field
                  size="lg"
                  validate={validateInput}
                  name="id"
                  component={Input}
                  style={{ backgroundColor: "white" }}
                />
              </CTableDataCell>
              <CButton
                type="submit"
                size="lg"
                color="primary"
                id="button-addon2"
                disabled={!valid}
              >
                Search
              </CButton>
            </CTableRow>
          </CTable>
        </form>
      )}
    />
  );
};

export default SearchForm;
