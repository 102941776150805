import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
// Import reducers from features
import sidebarReducer from "features/sidebar/slice";
import translationsApi from "features/translations/api";
import usersApi from "features/users/api";
import selectedServiceSlice from "features/selectedService/slice";

const store = configureStore({
	reducer: {
		sidebar: sidebarReducer,
		selectedService: selectedServiceSlice,
		[translationsApi.reducerPath]: translationsApi.reducer,
		[usersApi.reducerPath]: usersApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(translationsApi.middleware, usersApi.middleware),
});

setupListeners(store.dispatch);

export default store;
