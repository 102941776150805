import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
	useAddTranslationMutation,
	useUpdateByIdMutation,
	useDeleteTranslationMutation,
	useGetTranslationByIdQuery,
	useGetLcidsQuery,
	useGetServicePartQuery,
	useGetServiceQuery,
} from "features/translations/api";
import {
	CButton,
	CCol,
	CRow,
	CCardText,
	CCardBody,
	CCard,
	CCardTitle,
	CToast,
	CToastHeader,
	CToastBody,
	CToaster,
} from "@coreui/react";
import EditTranslationsRow from "containers/Forms/edittranslationrow";
import { useRef, useState } from "react";
import dayjs from "dayjs";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const Translation = (props) => {
	let params = useParams();
	const id = params.id;
	const [createTranslation] = useAddTranslationMutation();
	const [updateByRowId] = useUpdateByIdMutation();
	const onSubmit = (value) => {
		if (value.text == null || value.text == "") {
			return true;
		}
		if (value.rowId == null) {
			createTranslation(value);
			return true;
		}
		updateByRowId(value);
	};
	const navigate = useNavigate();
	const [deleteByRowId] = useDeleteTranslationMutation();
	const onDelete = (rowId) => {
		deleteByRowId(rowId);
		navigate("/");
	};
	const onSubmitRows = (event) => {
		setToast(exampleToast);
		event.preventDefault();
		const forms = event.target.closest(".card-body").querySelectorAll("form");
		if (forms != null) {
			forms.forEach((form) => {
				form.dispatchEvent(
					new Event("submit", { cancelable: true, bubbles: true })
				);
			});
		}
	};

	const exampleToast = (
		<CToast title="CoreUI for React.js" autohide={true}>
			<CToastHeader close>
				<svg
					className="rounded me-2"
					width="20"
					height="20"
					xmlns="http://www.w3.org/2000/svg"
					preserveAspectRatio="xMidYMid slice"
					focusable="false"
					role="img"
				>
					<rect width="100%" height="100%" fill="#007aff"></rect>
				</svg>
				<strong className="me-auto">Submission</strong>
				<small>Just now</small>
			</CToastHeader>
			<CToastBody>Translation updated successfully</CToastBody>
		</CToast>
	);

	const [toast, setToast] = useState(null);
	const toaster = useRef();
	const { data: selectedTranslation } = useGetTranslationByIdQuery(id);
	const { data: lcids } = useGetLcidsQuery();
	//   const { data: services } = useGetServiceQuery();
	// const { data: serviceParts } = useGetServicePartQuery();
	const serviceParts = [
		'BACKEND',
		'FRONTEND',
	];

	const services = [
		'HALDOR_EDUCATION',
		'HALDOR_GUARDIANS',
		'HALDOR_PLAGIARISM',
	];

	if (lcids == null || selectedTranslation == null) {
		return <h1></h1>;
	}

	return (
		<div>
			<h2>
				Edit translation for <i>({selectedTranslation[0].id})</i>
			</h2>
			<CRow
				xs={{ cols: 1, gutter: 3 }}
				md={{ cols: 2 }}
				lg={{ cols: 2 }}
				xl={{ cols: 2 }}
			>
				{services.map((service) => {
					return serviceParts.map((servicePart) => {
						let lastEditor = null;
						selectedTranslation.forEach((translation) => {
							if (translation.service != service || translation.servicePart != servicePart) {
								return true;
							}

							if (lastEditor == null) {
								lastEditor = translation;
								return true;
							}
							let current = lastEditor.created;
							let next = translation.created;
							if (lastEditor.edited != null) {
								current = lastEditor.edited;
							}
							if (translation.edited != null) {
								next = translation.edited;
							}
							if (dayjs(current).isBefore(next)) {
								lastEditor = translation;
							}
						});

						return (
							<CCol>
								<CCard>
									<CCardBody>
										<CCardTitle>
											{service} / {servicePart}
										</CCardTitle>

										<CCardTitle>
											{lastEditor != null ? (
												<div>
													{dayjs
														.utc(
															lastEditor.edited != null
																? lastEditor.edited
																: lastEditor.created
														)
														.format("YYYY-MM-DD HH:mm")}{" "}
													by{" "}
													{lastEditor.editor != null
														? lastEditor.editor
														: lastEditor.creator}
												</div>
											) : null}
										</CCardTitle>

										<CCardText>
											{lcids.map((lcid) => {
												let translation = selectedTranslation.find(
													(selectedTranslation) =>
														selectedTranslation.lcid == lcid &&
														selectedTranslation.service == service &&
														selectedTranslation.servicePart == servicePart
												);
												if (translation == null) {
													translation = {
														lcid: lcid,
														id: id,
														service: service,
														servicePart: servicePart,
													};
												}
												return (
													<div>
														<CCardText>
															<EditTranslationsRow
																translation={translation}
																onSubmit={onSubmit}
															></EditTranslationsRow>
														</CCardText>
													</div>
												);
											})}
										</CCardText>
										<CButton onClick={onSubmitRows}>Save</CButton>
										<CToaster ref={toaster} push={toast} placement="top-end" />
									</CCardBody>
								</CCard>
							</CCol>
						);
					});
				})}
			</CRow>
		</div>
	);
};

export default Translation;
