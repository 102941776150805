import React, { Component } from "react";

import auth from "lib/authentication";

class Logout extends Component {
  componentDidMount = async () => {
    await auth.logout();
  };

  render() {
    return <div className="loading-page-overview">
    </div>;
  }
}

export default Logout;
