import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import {
	MsalProvider,
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
} from "@azure/msal-react";

import store from "lib/store";
import msalInstance from "lib/msal";
import Login from "containers/Pages/Login/login";
import App from "containers/app";

import reportWebVitals from "./reportWebVitals";

import "styles/main.scss";

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<MsalProvider instance={msalInstance}>
					<AuthenticatedTemplate>
						<App />
					</AuthenticatedTemplate>

					<UnauthenticatedTemplate>
						<Login />
					</UnauthenticatedTemplate>
				</MsalProvider>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
