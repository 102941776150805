import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { setVisible, setUnfoldable } from "features/sidebar/slice";

import { setService, setServicePart } from "features/selectedService/slice";
import {
	useGetServicePartQuery,
	useGetServiceQuery,
} from "features/translations/api";

import {
	CSidebar,
	CSidebarBrand,
	CSidebarNav,
	CSidebarToggler,
	CNavGroup,
	CNavItem,
	CNavTitle,
} from "@coreui/react";

import { useNavigate } from "react-router-dom";

import HaldorLogo from "assets/haldor_logo_white.svg";

import "simplebar/dist/simplebar.min.css";
import CIcon from "@coreui/icons-react";
import { cilSchool } from "@coreui/icons";

const AppSidebar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const unfoldable = useSelector((state) => state.sidebar.unfoldable);
	const visible = useSelector((state) => state.sidebar.visible);
	// const { data: services } = useGetServiceQuery();
	const services = [
		'HALDOR_EDUCATION',
		'HALDOR_GUARDIANS',
		'HALDOR_PLAGIARISM',
	];
	// const { data: serviceParts } = useGetServicePartQuery();
	const serviceParts = [
		'BACKEND',
		'FRONTEND',
	];

	return (
		<CSidebar
			position="fixed"
			unfoldable={unfoldable}
			visible={visible}
			onVisibleChange={(visible) => {
				dispatch(setVisible(visible));
			}}
		>
			<CSidebarBrand className="d-none d-md-flex" to="/">
				<img src={HaldorLogo} height={35} alt="Haldor logo" />
			</CSidebarBrand>
			<CSidebarNav>
				<CNavTitle>Service</CNavTitle>
				{services != null && serviceParts != null
					? services.map((service) => {
						return (
							<CNavGroup toggler={service}>
								{serviceParts.map((servicePart) => {
									return (
										<CNavItem
											href="#"
											onClick={async () => {
												await dispatch(setService(service));
												await dispatch(setServicePart(servicePart));
												navigate("/", {
													replace: window.location.pathname == "/",
												});
											}}
										>
											<CIcon customClassName="nav-icon" icon={cilSchool} />{" "}
											{servicePart}
										</CNavItem>
									);
								})}
							</CNavGroup>
						);
					})
					: null}
			</CSidebarNav>

			<CSidebarToggler
				className="d-none d-lg-flex"
				onClick={() => dispatch(setUnfoldable(!unfoldable))}
			/>
		</CSidebar>
	);
};

export default React.memo(AppSidebar);
