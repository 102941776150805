import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import auth from "lib/authentication";
import getEnvironment from "lib/env";

const translationsApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: getEnvironment().baseUrl,
		prepareHeaders: auth.getHeaders,
	}),

	reducerPath: "translations",
	providesTags: ["Translations", "Translation"],
	endpoints: (build) => ({
		getTranslations: build.query({
			providesTags: (result) =>
				result
					? [
						...result.map(({ rowId }) => ({
							type: "Translations",
							id: rowId,
						})),
						{ type: "Translations", id: "LIST" },
					]
					: [{ type: "Translations", id: "LIST" }],
			query: () => ({
				url: "translations/service/haldor_education/lcid/en-us",
			}),
		}),
		getTranslationByRowId: build.query({
			query: (rowId) => ({
				url: `translations/${rowId}`,
			}),
			providesTags: (result) => [{ type: "Translations", id: result.rowId }],
		}),
		updateById: build.mutation({
			query: (translation) => ({
				url: `translations/${translation.rowId}`,
				method: "PUT",
				body: translation,
			}),
			invalidatesTags: (result) => [
				{ type: "Translations", id: result.rowId },
				{ type: "Translation", id: result.id },
			],
		}),
		addTranslation: build.mutation({
			query: (translation) => ({
				url: `translations`,
				method: "POST",
				body: translation,
			}),
			invalidatesTags: [{ type: "Translations", id: "LIST" }],
		}),
		deleteTranslation: build.mutation({
			query: (rowId) => ({
				url: `translations/${rowId}`,
				method: "DELETE",
			}),
			invalidatesTags: [{ type: "Translations", id: "LIST" }],
		}),
		searchTranslation: build.mutation({
			query: (search) => ({
				url: `translations/search/${search}`,
				method: "GET",
			}),
			invalidatesTags: [{ type: "Translations", id: "LIST" }],
		}),
		getTranslationById: build.query({
			query: (id) => ({
				url: `translations/id/${id}`,
			}),
			providesTags: (result, error, id) => [{ type: "Translation", id: id }],
		}),
		getMissingTranslations: build.query({
			query: (params) => {
				let url = `translations/missing/?_=`;
				if (params != null && params.service != null) {
					url += `&service=${params.service}`;
				}

				if (params != null && params.servicePart != null) {
					url += `&servicePart=${params.servicePart}`;
				}

				return {
					url,
				};
			},
		}),
		getLcids: build.query({
			query: () => ({
				url: `translations/lcids`,
			}),
		}),
		getService: build.query({
			query: () => ({
				url: `translations/services`,
			}),
		}),
		getServicePart: build.query({
			query: () => ({
				url: `translations/serviceparts`,
			}),
		}),
	}),
});

export const {
	useGetTranslationsQuery,
	useGetTranslationByRowIdQuery,
	useUpdateByIdMutation,
	useAddTranslationMutation,
	useDeleteTranslationMutation,
	useSearchTranslationMutation,
	useGetTranslationByIdQuery,
	useGetMissingTranslationsQuery,
	useGetLcidsQuery,
	useGetServiceQuery,
	useGetServicePartQuery,
} = translationsApi;

export default translationsApi;
