import React, { useEffect } from "react";
import { CButton } from "@coreui/react";
import { useState } from "react";
import auth from "lib/authentication";
import msalInstance from "lib/msal";
import HaldorLogo from "assets/haldor_logo_blue.svg";

const Login = () => {
	const [isLoading, setLoading] = useState(true);
	const onLogin = () => {
		auth.login();
	};
	const [errorMessages, setErrorMessages] = useState({});
	const renderErrorMessage = (name) =>
		name === errorMessages.name && (
			<div className="error">{errorMessages.message}</div>
		);

	useEffect(() => {
		if (window.location.hash.indexOf("code") > -1) {
			msalInstance.handleRedirectPromise().then((result) => {
				setLoading(false);
			});

			return true;
		}

		setLoading(false);
	}, []);

	if (isLoading) {
		return null;
	}

	return (
		<div>
			<div style={{ padding: '.35rem .75rem', borderBottom: '1px solid #e4e4eb' }}>
				<img src={HaldorLogo} height={50} alt="Haldor logo" />
			</div>

			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "10vh",
				}}
			>
				<div>
					<h1>Logga in till Haldor Translations</h1>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "10vh",
				}}
			>
				<CButton
					style={{ width: "600px" }}
					color="primary"
					size="lg"
					onClick={onLogin}
				>
					Login
				</CButton>
			</div>
		</div>
	);
};

export default Login;
