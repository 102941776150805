
const getEnvironment = () => {
	return {
		baseUrl: 'https://haldor-translations-prod-api.azurewebsites.net/api/',
		clientId: '7fea5d3a-b346-4189-8d20-9e5c516897db',
		authority: 'https://login.microsoftonline.com/3c0016b4-f672-496b-aa15-0876d5907f69/',
		scopes: [
			'api://7fea5d3a-b346-4189-8d20-9e5c516897db/access_as_user',
		],
	};
}

export default getEnvironment;