import { Form, Field } from "react-final-form";
import {
	CTable,
	CTableDataCell,
	CTableHead,
	CTableHeaderCell,
	CTableRow,
	CButton,
	CToast,
	CToastHeader,
	CToastBody,
	CToaster,
} from "@coreui/react";
import Input from "./input";
import Select from "./select";
import { useRef, useState } from "react";
import {
	useGetServicePartQuery,
	useGetServiceQuery,
	useGetLcidsQuery,
} from "features/translations/api";

const Translations = (props) => {
	const exampleToast = (
		<CToast title="CoreUI for React.js" autohide={true}>
			<CToastHeader close>
				<svg
					className="rounded me-2"
					width="20"
					height="20"
					xmlns="http://www.w3.org/2000/svg"
					preserveAspectRatio="xMidYMid slice"
					focusable="false"
					role="img"
				>
					<rect width="100%" height="100%" fill="#007aff"></rect>
				</svg>
				<strong className="me-auto">Submission</strong>
				<small>Just now</small>
			</CToastHeader>
			<CToastBody>Translation submitted successfully</CToastBody>
		</CToast>
	);
	const [toast, setToast] = useState(null);
	const toaster = useRef();
	const { data: lcids } = useGetLcidsQuery();
	//   const { data: services } = useGetServiceQuery();
	const { data: serviceParts } = useGetServicePartQuery();
	const services = [
		'HALDOR_EDUCATION',
		'HALDOR_GUARDIANS',
	];
	const validateInput = (value) => {
		if (value == "" || value == null) {
			return "Field is empty";
		}
		if (value.length < 1) {
			return "Too short searchterm";
		}
		return undefined;
	};

	return (
		<Form
			onSubmit={(value) => props.onSubmit(value)}
			initialValues={props.translation}
			render={({ handleSubmit, valid }) => (
				<form onSubmit={handleSubmit}>
					<CTable>
						<CTableHead>
							<CTableRow>
								<CTableHeaderCell>ID</CTableHeaderCell>
								<CTableHeaderCell>Text</CTableHeaderCell>
								<CTableHeaderCell>LCID</CTableHeaderCell>
								{props.translation == null ? (
									<CTableHeaderCell>Service</CTableHeaderCell>
								) : null}
								{props.translation == null ? (
									<CTableHeaderCell>ServicePart</CTableHeaderCell>
								) : null}
							</CTableRow>
						</CTableHead>

						<CTableRow>
							<CTableDataCell>
								<Field
									name="id"
									component={Input}
									style={{ backgroundColor: "white" }}
									validate={validateInput}
								/>
							</CTableDataCell>

							<CTableDataCell>
								<Field
									name="text"
									component={Input}
									style={{ backgroundColor: "white" }}
									validate={validateInput}
								/>
							</CTableDataCell>
							<CTableDataCell>
								{lcids != null ? (
									<Field
										validate={validateInput}
										name="lcid"
										component={Select}
										style={{ backgroundColor: "white" }}
										aria-label="Default select example"
										options={[
											{ label: "Select LCID" },
											...lcids.map((lcid) => {
												return { label: lcid, value: lcid };
											}),
										]}
									/>
								) : null}
							</CTableDataCell>

							<CTableDataCell>
								{services != null ? (
									<Field
										validate={validateInput}
										name="service"
										component={Select}
										style={{ backgroundColor: "white" }}
										aria-label="Default select example"
										options={[
											{ label: "Select service" },
											...services.map((service) => {
												return { label: service, value: service };
											}),
										]}
									/>
								) : null}
							</CTableDataCell>

							<CTableDataCell>
								{serviceParts != null ? (
									<Field
										validate={validateInput}
										name="servicePart"
										component={Select}
										style={{ backgroundColor: "white" }}
										aria-label="Default select example"
										options={[
											{ label: "Select Servicepart" },
											...serviceParts.map((servicePart) => {
												return { label: servicePart, value: servicePart };
											}),
										]}
									/>
								) : null}
							</CTableDataCell>
						</CTableRow>
					</CTable>

					<CButton
						onClick={() => setToast(exampleToast)}
						color="primary"
						size="lg"
						type="submit"
						style={{ borderRadius: "6px" }}
						disabled={!valid}
					>
						Submit
					</CButton>
					<CToaster ref={toaster} push={toast} placement="top-end" />
				</form>
			)}
		/>
	);
};

export default Translations;
