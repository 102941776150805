import getEnvironment from "lib/env";
import msalInstance, { msalConfig } from "lib/msal";

/**
 * @description Haldor Authentication class
 */
class Authentication {

	/**
	 * @description Execute login process
	 */
	login() {
		const request = this.getRequest();
		msalInstance.loginRedirect(request);
	}

	/**
	 * @description Sign out the user
	 */
	async logout() {
		msalInstance
			.acquireTokenSilent(this.getRequest())
			.then(async (response) => {
				const logoutRequest = {
					account: response.account,
					idTokenHint: response.idToken,
					postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
				};

				await msalInstance.logoutRedirect(logoutRequest);
			});
	}

	getRequest() {
		var accounts = msalInstance.getAllAccounts();

		const request = {
			scopes: [
				"openid",
				"profile",
				"offline_access",
				...getEnvironment().scopes,
			],
			account: accounts[0],
		};

		return request;
	}

	getHeaders = async (headers) => {
		const token = await this.getAccessToken();

		if (token) {
			headers.set("authorization", `Bearer ${token}`);
		}
		return headers;
	};

	/**
	 * @description This method will return access_token
	 */
	getAccessToken() {
		return new Promise((resolve, reject) => {
			var request = this.getRequest();

			//Silently acquires an access token
			msalInstance
				.acquireTokenSilent(request)
				.then((response) => {
					resolve(response.accessToken);
				})
				.catch((error) => {
					if (error.message.indexOf("no_account_error") == -1) {
						var event = new CustomEvent("login_required");
						document.dispatchEvent(event);
					} else {
						reject(error);
					}
				});
		});
	}
}

export default Authentication;
